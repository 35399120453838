* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
  background-color: #f6f6f6 !important;
  overscroll-behavior: none !important;
}
.css-8g8ihq {
  display: flex;
  flex-direction: column;
  gap: 0rem !important;
}
hr {
  margin-bottom: 0.000009rem !important;
  margin-top: 0.000009rem !important;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.000009rem !important;
}

/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
}
.chakra-container.songs.css-frdjbx .swiper-button-prev {
  left: 560px;
  right: auto;
  background-color: #00000052;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 50px;
  border: 1px solid #fff;
}
@media only screen and (max-width: 640px) {
  .chakra-container.songs.css-frdjbx .swiper-button-prev {
    left: 0px;
    right: auto;
    background-color: #00000052;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 50px;
    border: 2px solid #fff;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-container.songs.css-1yk2h4k .swiper-button-prev {
    left: 0px;
    right: auto;
    background-color: #00000052;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 50px;
    border: 2px solid #fff;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-container.songs.css-frdjbx .swiper-button-next {
    right: 0px;
    background-color: #00000052;
    height: 40px;
    width: 40px;
    color: #fff;
    border-radius: 50px;
    border: 2px solid #fff;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-container.songs.css-1yk2h4k .swiper-button-next {
    right: 0px;
    background-color: #00000052;
    height: 40px;
    width: 40px;
    color: #fff;
    border-radius: 50px;
    border: 2px solid #fff;
    display: none;
  }
}

.chakra-container.songs.css-frdjbx.swiper-button-next {
  right: 560px !important;
  background-color: #00000052;
  height: 30px;
  width: 30px;
  color: #fff;
  font-size: 10px;
  border-radius: 50px;
  border: 1px solid #fff;
}

.swiper-button-prev {
  left: 50px;
  right: auto;
  background-color: #00000052;
  height: 50px !important;
  width: 50px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
}
:root {
  --swiper-navigation-size: 18px !important;
}
.swiper-button-next {
  right: 50px;
  background-color: #00000052;
  height: 50px !important;
  width: 50px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
}
.swiper-slide {
  text-align: left;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.bannerImage
  .swiper-slide
  img {
  display: block;
  width: 100%;
  height: 94vh;
  object-fit: cover;
  background: #ffffff !important;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.bannerImage {
  margin-bottom: 0px;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.bannerImage
  .swiper-slide {
  text-align: left;
  font-size: 18px;
  background: rgb(255, 255, 255);
}
@media only screen and (max-width: 768px) {
.swiper-wrapper {
  align-items: center;
}}
.top-to-btm {
  position: relative;
}
.top-to-btm .icon-position {
  position: fixed;
  bottom: 40px;
  z-index: 20;
}
@media only screen and (max-width: 768px) {
  .top-to-btm .icon-position {
    bottom: 75px;
  }
}
.top-to-btm .icon-style {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.76);
  border: 1px solid #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  cursor: pointer;
  /* animation: movebtn 3s ease-in-out infinite; */
  /* transition: all 0.5s ease-in-out; */
}
.top-to-btm .icon-style:hover {
  animation: none;
  background: #fff;
  color: #333;
  border: 2px solid #333;
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
button.chakra-button.css-z4fyip:hover {
  transform: scale3d(1, 1, 1) !important;
}
button.chakra-button.css-z4fyip::before {
  background: #e91e63 !important;
  transform-origin: 100% 50% !important;
}
button.chakra-button.css-z4fyip:hover {
  transform-origin: 0 50% !important;
}

button.chakra-button.css-z4fyip:hover {
  transform-origin: 100% 50% !important;
}
.vertical-timeline-element {
  --line-color: #e3e6f1 !important;
}

input#fieldy {
  width: 530px;
}
@media only screen and (max-width: 640px) {
  input#fieldy {
    width: 215px !important;
  }
}
@media only screen and (max-width: 640px) {
  .css-wclpxa {
    display: grid !important;
    grid-gap: var(--chakra-space-6) !important;
    grid-template-columns: repeat(1, 1fr) !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 640px) {
  p.chakra-text.viewall.css-mdczz8 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.mobileviewpadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.mobileviewpaddinghome {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.mobileviewpaddingnav {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.chakra-stack.arrow-left.css-g931f2::before {
  content: " ";
  position: relative;
  left: auto;
  right: 100%;
  top: 0px;
  border-top: 10px solid transparent;
  border-right: 12px solid white;
  border-left: none;
  border-bottom: 10px solid transparent;
  margin-right: 0px;
}
.chakra-stack.arrow-right.css-g931f2::before {
  content: " ";
  position: relative;
  left: 100%;
  right: auto;
  top: 0px;
  border-top: 10px solid transparent;
  border-right: none;
  border-left: 12px solid white;
  border-bottom: 10px solid transparent;
  margin-left: 0px;
}
.vertical-timeline-element--work.oldvision.vertical-timeline-element {
  top: -180px;
}

.reverse {
  flex-direction: row-reverse;
}

.same {
  flex-direction: row;
}

@media only screen and (max-width: 640px) {
  .vertical-timeline-element--work.oldvision.vertical-timeline-element {
    top: 0px;
  }

  span.vertical-timeline-element-icon.bounce-in {
    padding-top: 0px !important;
  }

  .reverse {
    flex-direction: column;
  }

  .same {
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .css-bidhkv > *:not(style) ~ *:not(style) {
    margin-inline-end: 0rem !important;
  }
}
@media screen and (min-width: 48em) {
  .css-mc1n58 > *:not(style) ~ *:not(style) {
    margin-inline-start: 0rem !important;
  }
}
h2.chakra-heading.date-right.css-cpd7dc {
  text-align: right;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.bannerImage
  .swiper-wrapper {
  margin-bottom: 0px;
}
@media screen and (min-width: 48em) {
  .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.bannerImage
    .swiper-wrapper {
    margin-bottom: 0px;
  }
}
.chakra-stack.mobileviewpaddinghome.css-1vibjg2 {
  margin-top: -0.5rem;
}
.chakra-modal__content-container.css-g33wz1 {
  height: "600px";
  width: "1000px";
}
@media only screen and (max-width: 640px) {
  .chakra-modal__content-container {
    overflow: hidden !important;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-text.whatsNew {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.Explorer {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.member {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .chakra-stack.vision {
    display: none;
  }
}
.chakra-tabs__tablist button:focus {
  outline: none;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
}
div#chakra-modal-\:rj\: {
  top: 55px !important;
}
div#chakra-modal-\:r11\: {
  top: 55px !important;
}
button#accordion-button-\:r3\: {
  outline: none;
}
button#accordion-button-\:r9\: {
  outline: none;
}
button#accordion-button-\:rf\: {
  outline: none;
}
button#accordion-button-\:rl\: {
  outline: none;
}
.css-1elf7ca {
  min-height: 100vh;
  background: #ffffff !important;
}
div#timeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 70px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline1 .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline1 .vertical-timeline-element-content {
  position: relative;
  margin-left: 70px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline2 .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline2 .vertical-timeline-element-content {
  position: relative;
  margin-left: 70px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
@media only screen and (max-width: 768px) {
  div#timeline2 .vertical-timeline-element-content {
    margin-left: 40px;
  }
  div#timeline2.col-11 {
    max-width: 100% !important;
  }
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column-left:before {
  background-color: #dfdfdf;
}
div#timeline svg {
  display: initial;
  margin-right: 10px;
}
div#timeline1 svg {
  display: initial;
  margin-right: 10px;
}
div#timeline2 svg {
  display: initial;
  margin-right: 10px;
}
.chakra-tabs__tablist.ExhibiTimeline.css-b7kq4m button#tabs-\:r1d\:--tab-0 {
  outline: none;
}
.chakra-tabs__tablist.ExhibiTimeline.css-b7kq4m button#tabs-\:r1d\:--tab-1 {
  outline: none;
}
.ExhibiTimeline {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 0 !important;
  }
}
@media only screen and (min-width: 1170px) {
  div#timeline.exhibition-timeline {
    margin: 0.5em 0 !important;
  }
}
@media only screen and (min-width: 1170px) {
  div#timeline1.exhibition-timeline {
    margin: 0.5em 0 !important;
  }
}
@media only screen and (min-width: 768px) {
  div#timeline .vertical-timeline-element-content p {
    font-size: 12px !important;
    font-weight: 400;
  }
}
@media only screen and (min-width: 768px) {
  div#timeline1 .vertical-timeline-element-content p {
    font-size: 16px !important;
    font-weight: 400;
  }
}
@media only screen and (min-width: 768px) {
  div#timeline2 .vertical-timeline-element-content p {
    font-size: 16px;
    font-weight: 400;
  }
  div#timeline2 .vertical-timeline-element-date p {
    color: #000 !important;
    padding-top: 0;
    font-size: 12px;
  }
  div#timeline2 .vertical-timeline-element-date {
    padding-top: 0;
  }
  div#satyajit_timeline .vertical-timeline-element-content p {
    font-size: 16px;
    font-weight: 400;
  }
  div#satyajit_timeline .vertical-timeline-element-date p {
    color: #000 !important;
    padding-top: 0;
    font-size: 12px;
  }
  div#satyajit_timeline .vertical-timeline-element-date {
    padding-top: 0;
  }
  div#skkScreening_timeline .vertical-timeline-element-content p {
    font-size: 16px;
    font-weight: 400;
  }
  div#skkScreening_timeline .vertical-timeline-element-date p {
    color: #000 !important;
    padding-top: 0;
    font-size: 12px;
  }
  div#skkScreening_timeline .vertical-timeline-element-date {
    padding-top: 0;
  }
}
div#timeline .vertical-timeline-element-content p {
  margin: 0 !important;
  line-height: 2 !important;
}
div#timeline1 .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
div#timeline2 .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
.layout.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column-left {
  width: 100% !important;
  margin-top: 16px;
}
.awardsLayout.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column-left {
  width: 100% !important;
  margin-top: 4px;
}
.vertical-timeline {
  width: 100%;
  max-width: unset !important;
  margin: 0 auto;
  position: relative;
  padding: 0em 0 !important;
}
div#timeline .vertical-timeline-element-date {
  color: #000 !important;
}

@media screen and (min-width: 1400px) {
  div#chakra-modal-rightsidebar {
    position: fixed;
    right: auto !important;
    top: 64px !important;
    bottom: 0px;
    transform: translateX(75.1%) translateY(0px) translateZ(0px) !important;
  }
}
div#chakra-modal-rightsidebar {
  position: fixed;
  right: 0px;
  top: 64px !important;
  bottom: 0px;
  max-width: 400px !important;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}
div#chakra-modal-righteditsidebar {
  position: absolute;
  right: 0px;
  top: 64px !important;
  bottom: 0px;
  width: 100%;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}
div#chakra-modal-rightsidebar button.chakra-modal__close-btn.css-ftsnli {
  /* border: 1px solid; */
  border-radius: 16px;
  color: #fff;
  background-color: #000;
  font-size: 10px;
  outline: none !important;
  width: 20px;
  height: 20px;
  right: 22px;
  top: 20px;
}
.bottomfooter.css-1hgfz70 {
  margin-left: -16px;
  margin-right: -16px;
}
.bottomstickyfooter {
  margin: 0 -110px !important;
}
@media screen and (max-width: 768) {
  .bottomstickyfooter {
    margin: 0px !important;
  }
}
.bottomfooter {
  margin-left: -16px;
  margin-right: -16px;
}
.bottomfooter .swiper-wrapper {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .css-8w7fw1 {
    width: var(--chakra-sizes-20);
    display: block;
    border: 0px !important;
  }
}
.vertical-timeline {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 0em 0 !important;
}
.logoText {
  height: 25px !important;
  border: 1px solid #cbcbcb !important;
  border-radius: 5px !important;
}
.css-3w97ur {
  border-radius: 0px !important;
}
.chakra-stack.price span {
  margin-inline-start: 0rem;
}
.chakra-stack.price .chakra-text {
  margin-inline-start: 0rem;
  margin-top: 0.1rem !important;
}
.chakra-stack.price .chakra-stack {
  margin-top: 0.1rem !important;
}
.css-7mawxv:hover {
  outline: none;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 12px;
  }
}
.swiper.rightslideshow .swiper-button-prev {
  left: auto;
  right: 60px;
  background-color: #00000052;
  height: 40px !important;
  width: 40px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 80% !important;
}
.swiper.rightslideshow {
  background-color: #e8e8e8;
}
.swiper.rightslideshow .swiper-button-next {
  left: auto;
  right: 10px;
  background-color: #00000052;
  height: 40px !important;
  width: 40px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 80% !important;
}
.img-border-radius {
  border-radius: 10px;
}

/* div#chakra-modal-rightsidebar {
  max-width: 308px !important;
} */
.rightslideshow .swiper-wrapper {
  margin-bottom: 0px !important;
}
a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent !important;
}
.autherlink a {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent !important;
}
.chakra-ui-light::-webkit-scrollbar {
  display: none;
}
.verticalscroll::-webkit-scrollbar {
  display: none;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .exploretabs {
    margin-left: 15px !important;
    margin-right: 15px !important;
    gap: 10px 20px !important;
    flex-wrap: wrap !important;
  }
  .big-tab-tile {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}
.exploretabs {
  margin-left: 120px;
  margin-right: 120px;
}
.exploretabswithmasterlisttype {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .calendarheading {
    margin-bottom: 0px !important;
    font-size: 30px !important;
    line-height: 35px !important;
  }
  .pl-pr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.chakra-tabs__tablist.exploretablist {
  justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
  .chakra-tabs__tablist.exploretablist {
    padding: 5px 0px;
  }
  .calendar-tabs {
    margin: 10px;
  }
  .tab-panel-body {
    padding: 0px;
  }
}
.swiper-slide.myslide {
  flex-shrink: 0;
  width: 100% !important;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.chakra-tabs__tablist.tablist1.css-6sl1h7 {
  width: 310px;
  /* right: 96px !important; */
  margin-right: 101px;
}
.swiper-slide.myslide.swiper-slide img {
  width: 100% !important;
}
:root {
  --swiper-theme-color: #000000;
}
.collections {
  scroll-padding-top: 100px !important;
}
/* div#allpostsslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
} */
/* div#allpostsslider .swiper-button-next {
  right: -30px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
} */
/* div#allpostsslider .swiper-button-prev {
  left: -30px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
} */

div#allslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allslider .swiper-button-next {
  right: -22px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allslider .swiper-button-prev {
  left: -22px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

div#allsliderskk .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskk .swiper-button-next {
  right: 220px;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskk .swiper-button-prev {
  left: 220px;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#sliderskklyrics .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#sliderskklyrics .swiper-button-next {
  right: 220px;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#sliderskklyrics .swiper-button-prev {
  left: 220px;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkcollection .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkcollection .swiper-button-next {
  right: 250px;
  top: 260px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkcollection .swiper-button-prev {
  left: 250px;
  top: 260px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkcollection .swiper-button-next:after {
  content: none !important;
}
div#allsliderskkcollection .swiper-button-prev:after {
  content: none !important;
}
div#allsliderskk1 .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskk1 .swiper-button-next {
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskk1 .swiper-button-prev {
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskk2 .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskk2 .swiper-button-next {
  right: 165px !important;
  top: 480px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#allsliderskk2 .swiper-button-prev {
    left: 40% !important;
  }
  div#allsliderskk2 .swiper-button-next {
    right: 40% !important;
  }
}
div#allsliderskk2 .swiper-button-prev {
  left: 165px;
  top: 480px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#satyajitslidersynp .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#satyajitslidersynp .swiper-button-next {
  right: 215px;
  top: 560px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#satyajitslidersynp .swiper-button-prev {
  left: 215px;
  top: 560px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#satyajitslidersound .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#satyajitslidersound .swiper-button-next {
  right: 145px;
  top: 430px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#satyajitslidersound .swiper-button-prev {
  left: 145px;
  top: 430px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#satyajitslidersound .swiper-button-prev {
    left: 40%;
  }
  div#satyajitslidersound .swiper-button-next {
    right: 40%;
  }
}
div#allsliderskkPre .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkPre .swiper-button-next:after {
  content: none !important;
}
div#allsliderskkPre .swiper-button-prev:after {
  content: none !important;
}
div#allsliderskkPre .swiper-button-next {
  right: 135px;
  top: 103%;
  background-color: #00000000;
  height: 18px !important;
  width: 11px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkPre .swiper-button-prev {
  left: 135px;
  top: 103%;
  background-color: #00000000;
  height: 18px !important;
  width: 11px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkScreening .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkScreening .swiper-button-next {
  right: 150px;
  top: 430px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkScreening .swiper-button-prev {
  left: 150px;
  top: 430px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkAward .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkAward .swiper-button-next {
  right: 130px;
  top: 380px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkAward .swiper-button-prev {
  left: 130px;
  top: 380px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkCast .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkCast .swiper-button-next {
  right: 44%;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkCast .swiper-button-prev {
  left: 44%;
  top: 460px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#allsliderskkCast .swiper-button-prev {
    top: 430px;
  }
  div#allsliderskkCast .swiper-button-next {
    top: 430px;
  }
}
div#allsliderskkEditorial .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkEditorial .swiper-button-next {
  right: 85px;
  top: 462px;
  background-color: #00000000;
  height: 20px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(90deg);
}
div#allsliderskkEditorial .swiper-button-prev {
  left: 125px;
  top: 0px;
  background-color: #00000000;
  height: 20px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(90deg);
}
@media only screen and (max-width: 768px) {
  div#allsliderskkEditorial .swiper-button-prev {
    left: 45px;
  }
  div#allsliderskkEditorial .swiper-button-next {
    right: 45px;
    top: 395px;
  }
}
div#allsliderskkObject .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkObject .swiper-button-next {
  right: 250px;
  top: 300px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkObject .swiper-button-prev {
  left: 250px;
  top: 300px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#allsliderskkObject .swiper-button-next {
    right: 20px;
    top: 50%;
    background-color: #00000000;
    height: 24px !important;
    width: 15px !important;
    color: #000 !important;
    border-radius: 50px;
    border: 0px solid #fff;
    font-weight: 900;
    background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  div#allsliderskkObject .swiper-button-prev {
    left: 20px;
    top: 50%;
    background-color: #00000000;
    height: 24px !important;
    width: 15px !important;
    color: #000 !important;
    border-radius: 50px;
    border: 0px solid #fff;
    font-weight: 900;
    background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
}
div#allsliderskkObject .swiper-button-next:after {
  content: none !important;
}
div#allsliderskkObject .swiper-button-prev:after {
  content: none !important;
}
div#postdetailslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#postdetailslider .swiper-button-next {
  right: 250px;
  top: 30%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#postdetailslider .swiper-button-prev {
  left: 250px;
  top: 30%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#postdetailslider .swiper-button-next {
    right: 20px;
    top: 15%;
    background-color: #00000000;
    height: 24px !important;
    width: 15px !important;
    color: #000 !important;
    border-radius: 50px;
    border: 0px solid #fff;
    font-weight: 900;
    background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right1.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  div#postdetailslider .swiper-button-prev {
    left: 20px;
    top: 15%;
    background-color: #00000000;
    height: 24px !important;
    width: 15px !important;
    color: #000 !important;
    border-radius: 50px;
    border: 0px solid #fff;
    font-weight: 900;
    background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left1.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
}
div#postdetailslider .swiper-button-next:after {
  content: none !important;
}
div#postdetailslider .swiper-button-prev:after {
  content: none !important;
}
div#allsliderskkBook .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkBook .swiper-button-next {
  right: -8px;
  top: 60%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkBook .swiper-button-prev {
  left: -10px;
  top: 60%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#allsliderskkBook .swiper-button-next {
    right: 0%;
  }
  div#allsliderskkBook .swiper-button-prev {
    left: 0%;
  }
}
.swiper-button-next:after {
  content: none;
}
div#allsliderskkBook .swiper-button-prev:after {
  content: none;
}
div#allsliderskkArchive .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkArchive .swiper-button-next {
  right: 150px;
  top: 360px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkArchive .swiper-button-prev {
  left: 150px;
  top: 360px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#allsliderskkArchive .swiper-button-next {
    right: 40%;
    top: 345px;
  }
  div#allsliderskkArchive .swiper-button-prev {
    left: 40%;
    top: 345px;
  }
}
div#allsliderSatyajitLib .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderSatyajitLib .swiper-button-next {
  right: 166px;
  top: 364px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderSatyajitLib .swiper-button-prev {
  left: 166px;
  top: 364px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkArchive1 .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderskkArchive1 .swiper-button-next {
  right: 24px;
  top: 50%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderskkArchive1 .swiper-button-prev {
  left: 24px;
  top: 50%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#biblographysliderskkdrawer .swiper {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#biblographysliderskkdrawer .swiper-button-next {
  right: 50px;
  top: 50%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#biblographysliderskkdrawer .swiper-button-prev {
  left: 50px;
  top: 50%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  div#biblographysliderskkdrawer .swiper-button-next {
    right: 25px;
  }
  div#biblographysliderskkdrawer .swiper-button-prev {
    left: 25px;
  }
}
div#allsliderahj .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderahj .swiper-button-next {
  right: 24px;
  top: 55%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderahj .swiper-button-prev {
  left: 24px;
  top: 55%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* Integrated Timeline Modal Carousel */
div#modalslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#modalslider .swiper-button-next {
  top: 28% !important;
  right: -22px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#modalslider .swiper-button-prev {
  top: 28% !important;
  left: -22px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* Integrated Timeline Top Year Range Carousel */
div#yearrangeslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#yearrangeslider .swiper-button-next {
  right: 0px;
  top: 45%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right-white.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 9999;
}
div#yearrangeslider .swiper-button-prev {
  left: 0px;
  top: 45%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left-white.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 9999;
}
div#yearrangeslider .swiper-button-next:after {
  content: none !important;
}
div#yearrangeslider .swiper-button-prev:after {
  content: none !important;
}
div#homeslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#homeslider .swiper-button-next {
  right: 30px;
  background-color: #00000000;
  height: 35px !important;
  width: 21px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevronright.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#homeslider .swiper-button-next::after {
  content: none !important;
}
div#homeslider .swiper-button-prev::after {
  content: none !important;
}
div#homeslider .swiper-button-prev {
  left: 30px;
  background-color: #00000000;
  height: 34px !important;
  width: 21px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevronleft.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
input#search::placeholder {
  color: black;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
button:focus {
  outline: none;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
}
@media only screen and (max-width: 768px) {
  .chakra-stack.homepagecontent {
    margin: 10px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .chakra-stack.rulerssdata {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1440px) {
  div#chakra-modal-rightsidebarsmall {
    position: fixed;
    right: auto !important;
    top: 64px !important;
    bottom: 0px;
    max-width: 400px !important;
    border-left: 0.1px solid;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  */
    /* transform: translateX(169%) translateY(0px) translateZ(0px) !important; */
    transform: translateX(75.1%) translateY(0px) translateZ(0px) !important;
  }
  div#chakra-modal-rightsidebarsmall1 {
    position: fixed;
    right: auto !important;
    top: 64px !important;
    bottom: 0px;
    width: 100%;
    /* transform: translateX(169%) translateY(0px) translateZ(0px) !important; */
    transform: translateX(56.4%) translateY(0px) translateZ(0px) !important;
  }
}
div#chakra-modal-rightsidebarsmall {
  position: absolute;
  right: 0px;
  top: 64px !important;
  bottom: 0px;
  width: 100%;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}

div#chakra-modal-listgridviewbtn {
  position: absolute;
  right: 0px;
  top: 64px !important;
  bottom: 0px;
  width: 100%;
  gap: 0px !important;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}
@media only screen and (min-width: 1440px) {
  div#chakra-modal-humbergermenu {
    position: fixed;
    left: auto !important;
    top: 64px !important;
    bottom: 0px;
    width: 100%;
    transform: translateX(-220.2%) translateY(0px) translateZ(0px) !important;
  }
}
div#chakra-modal-humbergermenu {
  /* position: absolute;
  left: auto!important; */
  top: 64px !important;
  bottom: 0px;
  width: 100%;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}
div#chakra-modal-humbergermenu {
  max-width: 318px !important;
}
p.chakra-text.topmargin {
  margin-top: 0.1rem !important;
}
.calender .swiper-slide {
  justify-content: flex-start !important;
}
div#datecarosuel .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#datecarosuel .swiper-button-next {
  right: 0px;
  background-color: #00000000;
  height: 58px !important;
  width: 70x !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/date.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 20%;
}
div#datecarosuel .swiper-button-next::after {
  content: none !important;
}
div#datecarosuel .swiper-button-prev {
  left: -30px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  visibility: hidden !important;
}
button.chakra-button.humbargermenu {
  right: -15px;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 15px !important;
  left: 18px;
  height: 100%;
  width: 2px !important;
  background: var(--line-color);
}
.swiper-slide.mapyear {
  background: #fff0 !important;
}
label {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

div#slideshowinside .swiper-button-prev {
  left: auto;
  right: 155px;
  background-color: #00000052;
  height: 34px !important;
  width: 34px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92% !important;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left-white.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}
@media only screen and (max-width: 568px) {
  div#slideshowinside .swiper-button-prev {
    left: auto;
    right: 100px;
    background-color: #00000052;
    height: 30px !important;
    width: 30px !important;
    color: #fff !important;
    border-radius: 50px;
    border: 1px solid #fff;
    top: 91% !important;
    z-index: 99999;
  }
}
div#slideshowinside .swiper-button-next {
  left: auto;
  right: 40px;
  background-color: #00000052;
  height: 34px !important;
  width: 34px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92%;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right-white.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}
@media only screen and (max-width: 568px) {
  div#slideshowinside .swiper-button-next {
    left: auto;
    right: 12px !important;
    background-color: #00000052;
    height: 30px !important;
    width: 30px !important;
    color: #fff !important;
    border-radius: 50px;
    border: 1px solid #fff;
    top: 91% !important;
    z-index: 99999;
  }
}
.swiper.slideshowinside {
  background-color: #e8e8e8;
}
div#slideshowinside .swiper-wrapper {
  margin-bottom: 0px !important;
}
div#slideshowinside .swiper-pagination-fraction {
  top: 91%;
  left: -92px;
  width: 100%;
  text-align: end;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 568px) {
  div#slideshowinside .swiper-pagination-fraction {
    top: 88%;
    left: -55px;
    width: 100%;
    text-align: end;
    font-size: 16px;
    font-weight: 700;
  }
}
div#slideshowinside.swiper-button-next:after {
  display: none;
  content: none;
}
div#homeslider.swiper-button-next:after {
  display: none;
  content: "none";
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 100% !important;
  }
}
.swiper.visionslideshow .swiper-button-prev {
  left: auto;
  right: 120px;
  background-color: #00000052;
  height: 40px !important;
  width: 40px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92% !important;
  z-index: 99999;
}
.swiper.visionslideshow {
  background-color: #e8e8e8;
}
.swiper.visionslideshow .swiper-button-next {
  left: auto;
  right: 10px;
  background-color: #00000052;
  height: 40px !important;
  width: 40px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92% !important;
}
.visionslideshow .swiper-wrapper {
  margin-bottom: 0px !important;
}
.visionslideshow .swiper-pagination-fraction {
  bottom: 22px;
  left: -60px;
  width: 100%;
  text-align: end;
  font-size: 20px;
  font-weight: 700;
}
/* .wrapper {
  border: 1px solid rgb(219, 219, 219);
  border-radius: 3px;
}

.editor {
  background-color: rgb(245, 245, 245);
} */
.Bgimage {
  position: relative;
  /* text-align: center; */
  color: white;
}
.landingImg {
  position: relative;
  /* text-align: center; */
  width: 992px;
  height: 520px;
  color: white;
  display: contents;
}
.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
}
.bottom-right {
  top: 78%;
  right: 5%;
}
.bottom-center {
  position: absolute;
  bottom: 20px;
}
.centered {
  position: absolute;
  left: 48%;
  transform: translate(-50%, -50%);
}
.on-image-centered-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -65%);
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .bottom-right {
    position: absolute;
    top: 68% !important;
    right: 3%;
  }
}
@media only screen and (max-width: 568px) {
  .bottom-right {
    position: absolute;
    top: 60% !important;
    right: 0%;
  }
}
@media only screen and (max-width: 568px) {
  .centered {
    position: absolute;
    left: 30%;
    transform: translate(-50%, -50%);
  }
  .bottom-center {
    position: absolute;
    bottom: -10px;
  }
}

.hide {
  display: none;
}
.top-right:hover + .hide {
  display: block;
  color: red;
}
#DivForHoverItem:hover #HiddenText {
  display: flex;
  align-items: center !important;
}
@media only screen and (max-width: 568px) {
  div#DivForHoverItem {
    width: 100% !important;
    height: 100% !important;
  }
}
#HiddenText {
  display: none;
  align-items: center !important;
}
div#subsliderAHJ.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -56px;
  left: 0px !important;
}
div#subslidershraza.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -57px;
  left: 78px !important;
}
@media only screen and (min-width: 1440px) {
  div#subslidershraza.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -57px;
    left: 60px !important;
  }
}
@media only screen and (max-width: 768px) {
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -130px;
    left: 0px !important;
  }
}
@media only screen and (min-width: 1440px) {
  div#subslidershraza.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -57px;
    left: 60px !important;
  }
}
div#subslider.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -57px;
  left: 130px !important;
}
@media only screen and (max-width: 568px) {
  div#subsliderssk.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px !important;
    left: 0px !important;
  }
}
div#subsliderssk.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: 60px;
}
@media only screen and (max-width: 568px) {
  div#subleftsliderssk.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -65px;
    left: 0px !important;
  }
}
div#subleftsliderssk.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: 0px !important;
}
@media only screen and (max-width: 568px) {
  div#subleftsliderssk.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px;
    left: 0px !important;
  }
}
@media only screen and (max-width: 568px) {
  div#subleftsliderahj.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -65px;
    left: 0px !important;
  }
}
div#subleftsliderahj.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: -58px !important;
}
@media only screen and (max-width: 568px) {
  div#subleftsliderahj.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px;
    left: 0px !important;
  }
}
div#subleftslidershraza.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -66px;
  left: 180px !important;
}
div#subleftslidersatyajit.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: 25px !important;
}
#subleftsliderlocation.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: -45px !important;
}
div#subleftslidereventexhibition.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -60px;
  left: 85px !important;
}
div#subleftsliderssb.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -60px;
  left: 10px !important;
}
@media only screen and (max-width: 568px) {
  div#subleftslidersatyajit.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px;
    left: 0px !important;
  }
  div#subleftsliderlocation.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px;
    left: 0px !important;
  }
}
div#subleftsliderArtist.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -65px;
  left: 105px !important;
}
@media only screen and (max-width: 568px) {
  div#subleftsliderArtist.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -120px;
    left: 0px !important;
  }
}
div#subslidersanjeevk.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -56px;
  left: 102px !important;
}
div#subsliderebrahimA.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -56px;
  left: 154px !important;
}
.zoomed-image {
  transform: scale(2); /* Adjust the zoom level as needed */
  transition: transform 0.3s; /* Add a smooth transition */
}
.sliderborder {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.subcribe-button {
  margin-right: -70px !important;
}
@media only screen and (max-width: 768px) {
  .subcribe-button {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  div#subslider.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -130px;
    left: 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  .a-graphy-heart-line {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
  color: #333333;
  font-family: "Segoe UI";
  font-size: 15px;
  font-weight: 600;
  background-color: #fff;
  padding: 0px 23px;
}
.swiper-pagination-bullet {
  padding: 5px 0px !important;

  border-radius: 0;
  width: auto;
  height: 30px;
  text-align: right !important;
  line-height: 30px;
  font-size: 12px;
  color: #000;
  opacity: 1;
}
.masterlistslider {
  margin-left: 120px;
  margin-right: 120px;
  margin-bottom: 30px;
}
.introText {
  margin-left: 64px;
  margin-right: 64px;
  margin-bottom: 30px;
}
.introssbText {
  /* margin-left: 32px;
  margin-right: 64px; */
  margin-bottom: 30px;
}
/* @media only screen and (min-width: 1080px) {
  .masterlistslider {
    margin-left: 110vw;
    margin-right: 122vw;
    margin-bottom: 30vw;
  }
} */
@media only screen and (max-width: 768px) {
  .masterlistslider {
    margin: 0px !important;
    padding-top: 10px !important;
  }
}
.swiper-pagination-bullet-active {
  border-bottom: 2px solid #333333;
}
@media only screen and (max-width: 768px) {
  .osianamaworld {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.osianamaworld {
  margin-left: 112px;
  margin-right: 168px;
}
#divcontent {
  position: relative;
}
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
}
.ribbon1 {
  position: absolute;
  top: 80%;
  right: 0;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 768px) {
  .ribbon1 {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(-10%, -50%);
    z-index: 999;
  }
}
.css-1f473lj:hover {
  background: rgb(0 0 0 / 0%) !important;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.ahncslider
  .swiper-slide
  img {
  background: #edf7fd !important;
}
.swiper.ahncslider.swiper-button-next::after {
  right: -65px !important;
}

.indexbutton {
  top: 34%;
  right: 0px;
}
@media only screen and (max-width: 568px) {
  .chakra-stack.exploreIndexTab {
    position: relative;
    top: -45px;
    right: 50px;
  }
}
/* On Slider ttile, button, text css for responsive */

@media only screen and (max-width: 568px) {
  .btn-on-slide {
    padding: 5px 5px !important;
    height: 1.5rem !important;
    font-size: 12px !important;
  }
  .title-on-slide {
    font-size: 26px !important;
    line-height: 26px !important;
    word-break: break-word;
  }
  .top-right {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 70% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-wrap-onmobile {
    flex-wrap: wrap;
  }
}
.explore-posts-category {
  margin-left: 115px;
  margin-right: 100px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 568px) {
  .explore-posts-category {
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: wrap;
  }
  .selected-tab[aria-selected="true"] {
    border-bottom: var(--chakra-borders-4px);
    border-color: #333333;
    border-radius: 4px;
    height: 30px;
    margin-top: 5px !important;
  }
}
@media only screen and (max-width: 768px) {
  .masterlist-inbox {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .masterlist-inbox th,
  td {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .detail-page {
    padding: 15px !important;
  }
  .on-mobile-gridview.css-1auuqwc {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 0px !important;
  }
  .des-col-on-mobile {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }
  .grid-item-on-mobile {
    width: 100% !important;
    padding: 15px 0px !important;
  }
}
.filter-sticky-button {
  position: fixed;
  left: auto;
  font-size: 1rem;
  height: auto;
  line-height: 10px;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  background: rgba(255, 255, 255, 0.5);
  border: 0.8px solid #989898;
}
.nav-menulist {
  position: relative !important;
  right: 60% !important;
  display: inline-block;
}
.chakra-modal__content-container {
  z-index: 9999 !important;
}
.quzi-circle {
  left: -145px;
  top: 5px;
}
.tab-border {
  padding: 0px 20px;
}
p.chakra-text.masterlist-tab {
  padding: 0px 20px;
}
p.chakra-text.masterlist-tab:focus {
  border-bottom: "4px";
  border-color: "#333333";
  border-radius: "4px";
  height: "30px";
}
.clicked {
  border-bottom: 4px solid #333333;
  /* border-radius: 4px; */
}
.box-filter {
  position: sticky;
  top: 20%;
  flex-direction: row-reverse;
  width: 235px;
  height: auto;
  margin-right: -81px;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 9999;
  background-color: #ffffff;
}
@media (min-width: 1400px) {
  .box-filter {
    position: sticky;
    top: 17%;
    flex-direction: row-reverse;
    width: 235px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
  .box-filter-satyajit-screen {
    top: 160px;
    flex-direction: row-reverse;
    width: 235px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
  .box-filter-satyajit {
    top: 134px;
    flex-direction: row-reverse;
    width: 235px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
}
.box-filter1 {
  position: sticky;
  top: 26%;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  margin-right: -81px;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 9999;
  background-color: #ffffff;
}
.box-filterSKK {
  position: sticky;
  top: 230px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
}
.box-filter-personality-actor {
  position: fixed;
  top: 220px;
  right: 110px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
}
.box-filterNemai {
  position: sticky;
  top: 0px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
}
.box-filterSatyajitray {
  position: sticky;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  margin-right: -2px;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 9999;
  background-color: #ffffff;
}
@media (min-width: 1400px) {
  .box-filter1 {
    position: sticky;
    top: 24%;
    flex-direction: row-reverse;
    width: 250px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
}
@media (min-width: 1600px) {
  .box-filter1 {
    position: sticky;
    top: 20%;
    flex-direction: row-reverse;
    width: 250px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
}
@media (min-width: 1800px) {
  .box-filter1 {
    position: sticky;
    top: 17%;
    flex-direction: row-reverse;
    width: 250px;
    height: auto;
    margin-right: -81px;
    padding: 15px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: 0.8px solid #dcdcdc;
    z-index: 9999;
    background-color: #ffffff;
  }
}
.pushed-left {
  margin-right: 200px; /* Adjust the value as per your needs */
}
.animate {
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: "linear";
  transition-delay: 0.3s;
}
.min-max-height {
  min-height: 170px;
  max-height: 180px;
}
.mySwiper-year .swiper-wrapper {
  display: flex;
  align-items: baseline !important;
  margin-bottom: 20px;
}
.all-years {
  width: max-content;
  padding: 22px 0px;
  color: white;
}
.closebtn {
  position: absolute !important;
  right: 10%;
  top: 1% !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}
@media (max-width: 567px) {
  .closebtn {
    position: absolute !important;
    right: 5%;
    top: 22% !important;
    color: white !important;
    background-color: black !important;
    border-radius: 20px !important;
    font-size: 10px !important;
  }
}
.art-he-closebtn {
  position: absolute !important;
  right: 10%;
  top: 1% !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}
@media (max-width: 567px) {
  .art-he-closebtn {
    position: absolute !important;
    right: 5%;
    top: 8% !important;
    color: white !important;
    background-color: black !important;
    border-radius: 20px !important;
    font-size: 10px !important;
  }
}
.editorialclosebtn {
  position: absolute !important;
  right: 10%;
  top: 1% !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}
@media (max-width: 567px) {
  .editorialclosebtn {
    position: absolute !important;
    right: 5%;
    top: 5% !important;
    color: white !important;
    background-color: black !important;
    border-radius: 20px !important;
    font-size: 10px !important;
  }
}
.intgTimelineclosebtn {
  position: absolute !important;
  right: 10%;
  top: 1% !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}
@media (max-width: 567px) {
  .intgTimelineclosebtn {
    position: absolute !important;
    right: 2%;
    top: 1% !important;
    color: white !important;
    background-color: black !important;
    border-radius: 20px !important;
    font-size: 10px !important;
  }
}
.essayclosebtn {
  position: absolute !important;
  right: 8%;
  top: 2px !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}
img.hoverImg:hover {
  background: black;
  opacity: 0.2;
}
.wordspacing {
  word-spacing: 5px;
}
@media only screen and (max-width: 768px) {
  .wordspacing {
    word-spacing: 5px;
    padding-top: 27px;
  }
}
.scrollText {
  max-height: 122px;
  overflow-x: hidden;
  overflow-y: auto;

  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
.scrollText::-webkit-scrollbar {
  display: none;
}
div#yearcarosuel .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#yearcarosuel.swiper-button-next {
  right: 0px;
  background-color: #00000000;
  height: 58px !important;
  width: 70x !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/date.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 20%;
}
div#yearcarosuel .swiper-button-next::after {
  content: none !important;
}
div#yearcarosuel .swiper-button-prev {
  left: 0px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  visibility: hidden !important;
}
.h-line:after {
  position: absolute;
  font-family: FontAwesome;
  display: block;
  margin-left: 47%;
  margin-top: -50px;
  font-size: 60pt;
  color: #0000;
  content: url(https://images.derivaz-ives.com/icons/about_us.png);
  background-repeat: no-repeat;
  font-weight: 300;
  background-color: #fff;
  padding: 0 20px;
}
input#indexsearchbox {
  background-color: #eeeeee;
  font-size: 14px;
}
.fixTableHead td {
  text-align: left;
}
hr.bottom-border-radius {
  border-bottom-width: 5px;
  border-color: #767676;
  border-radius: 5px;
}
.tablist-border {
  border: 1px solid;
  width: fit-content;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  margin-bottom: 10px;
  border-color: #989898;
}
div#filterstickywbtn {
  position: sticky;
  top: 180px;
  flex-direction: row-reverse;
  border: 0.8px solid;
  border-color: #dcdcdc;
  padding: 32px 4px;
  cursor: pointer;
  margin-right: -15px;
  align-items: center;
  background: white;
}
div#filterstickywbtnnemaiGhosh {
  position: absolute;
  top: -56px;
  flex-direction: row-reverse;
  border: 0.8px solid;
  border-color: #dcdcdc;
  padding: 32px 4px;
  cursor: pointer;
  margin-left: -45px !important;
  align-items: center;
}
div#filterstickywbtnsatyajitscreen {
  flex-direction: row-reverse;
  border: 0.8px solid;
  border-color: #dcdcdc;
  padding: 42px 14px 44px 19px;
  top: 160px;
  cursor: pointer;
  z-index: 1;
  margin-right: -15px;
  align-items: center;
}
div#filterstickywbtnsatyajit {
  flex-direction: row-reverse;
  border: 0.8px solid;
  border-color: #dcdcdc;
  padding: 42px 14px 44px 19px;
  top: 130px;
  cursor: pointer;
  z-index: 1;
  margin-right: -15px;
  align-items: center;
}
/* @media (max-width: 1280px) {
  section#popover-content-\:r9\:.popupcontent {
    max-height: 460px !important;
    overflow-y: scroll !important;
  }
}
@media (max-width: 1280px) {
  section#popover-content-\:r1n\:.popupcontent {
    max-height: 560px !important;
    overflow-y: scroll !important;
  }
}
@media (max-width: 1550px) {
  section#popover-content-\:r9\:.popupcontent {
    max-height: 650px !important;
    overflow-y: scroll !important;
  }
}
@media (max-width: 1550px) {
  section#popover-content-\:r1n\:.popupcontent {
    max-height: 660px !important;
    overflow-y: scroll !important;
  }
} */
@media (min-width: 1400px) {
  .middle-section-height {
    height: 100vh;
  }
}

.imageZoomIn {
  transform: scale(1.045);
  transition: transform 0.25s ease;
}

.imageZoomOut {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: transform 0.25s ease;
}
@media (max-width: 1279px) {
  .demoModalContent {
    right: 24.7%;
    height: 780px;
  }
  .demoContentImage {
    width: 860px;
    height: 737px;
  }
}
@media only screen and (max-width: 768px) {
  .demoContentImage {
    width: 100% !important;
    height: 100% !important;
  }
}
/* @media (min-width:1400px) { */
.demoModalContent {
  position: fixed;
  width: 100%;
  /* right: 25%; */
  height: 100%;
}
.demoContentImage {
  width: 860px;
  height: 760px;
  padding: 12px;
}

/* } */
@media (min-width: 1400px) {
  .scannedModal {
    right: 25.4%;
    height: 100%;
  }
  .imageScanned {
    width: 860px;
    height: 99%;
  }
}
@media (max-width: 1279px) {
  .scannedModal {
    position: fixed;
    width: 100%;
    right: 21.9%;
    height: 780px;
  }
  .imageScanned {
    width: 860px;
    height: 98%;
  }
}
/* CSS or styling file */
@media screen and (min-width: 1280px) {
  .Edition_class {
    width: 67%;
  }
}
@media screen and (min-width: 1400px) {
  .Edition_class {
    width: 70%;
  }
  .Exhibition_class {
    width: 69%;
  }
}

div#esaaySlider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#esaaySlider .swiper-button-next {
  right: -45%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#esaaySlider .swiper-button-prev {
  left: -40%;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.box-filterArtHeritage {
  position: sticky;
  top: 180px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
  max-height: 580px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.box-filterscreening {
  position: fixed;
  top: 235px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
  max-height: 580px;
}
.box-filterArtHeritage::-webkit-scrollbar {
  width: 2px;
}
.chakra-container.nemaicont {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.chakra-container.nemaicont::-webkit-scrollbar {
  width: 0px;
}
.box-filterScreening {
  position: sticky;
  top: 252px;
  flex-direction: row-reverse;
  width: 250px;
  height: auto;
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 0.8px solid #dcdcdc;
  z-index: 99;
  background-color: #ffffff;
}
div#allsliderArtArchive .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderArtArchive .swiper-button-next {
  right: 110px;
  top: 290px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderArtArchive .swiper-button-prev {
  left: 110px;
  top: 290px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderArtArchive .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allsliderArtArchive .swiper-button-next {
  /* right: 24px; */
  top: 340px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allsliderArtArchive .swiper-button-prev {
  /* left: 24px; */
  top: 340px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

div#arttimeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#arttimeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 70px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#arttimeline svg {
  display: initial;
  margin-right: 10px;
}
@media only screen and (min-width: 768px) {
  div#arttimeline .vertical-timeline-element-content p {
    font-size: 16px !important;
    font-weight: 400;
  }
}
div#arttimeline .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
.layoutDrawer.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column-left {
  width: 100% !important;
  margin-top: 32px;
}

div#editionTimeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#editionTimeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 70px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#editionTimeline .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
div#satyajit_timeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#satyajit_timeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 76px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
@media only screen and (max-width: 768px) {
  div#satyajit_timeline .vertical-timeline-element-content {
    position: relative;
    margin-left: 55px;
  }
  div#satyajit_timeline
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    display: none;
  }
}
div#satyajit_timeline svg {
  display: initial;
  margin-right: 10px;
}
div#satyajit_timeline .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
div#skkScreening_timeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#skkScreening_timeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 76px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#skkScreening_timeline svg {
  display: initial;
  margin-right: 10px;
}
div#skkScreening_timeline .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
.introReadmore {
  font-weight: 800;
  color: white;
  cursor: pointer;
}
.introReadmore:hover {
  text-decoration: none;
}
div#allslidersatyajitAward .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allslidersatyajitAward .swiper-button-next {
  right: 110px;
  top: 365px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allslidersatyajitAward .swiper-button-prev {
  left: 110px;
  top: 365px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.chakra-modal__overlay {
  right: 0 !important;
  margin: 0 auto;
  width: 1400px !important;
}
.card {
  position: relative;
  background: radial-gradient(#111 97%, #000 0%);
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 13px;
}
.card1 {
  position: relative;
  background: white;
  overflow: hidden;
  cursor: pointer;
}
.card2 {
  position: relative;
  background: radial-gradient(#ffffff 97%, #000 0%);
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 13px;
}
img {
  max-width: 100%;
  display: block;
}
.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  text-align: center;
  color: #fff;
}
.text-p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
}
.card:hover .overlay .text-p {
  opacity: 1;
  transform: translateY(90px);
}
.card2:hover .overlay .text-p {
  opacity: 1;
  transform: translateY(90px);
}
.card img {
  /* transform: scale(1.1); */
  transition: 0.3s ease-out;
}
.card:hover img {
  transform: scale(1.1) translateY(0px);
  opacity: 0.1;
}
.card1:hover img {
  transform: scale(1.1) translateY(0px);
  /* opacity: 0.1; */
}
.card2:hover img {
  transform: scale(0.025.1) translateY(0px);
  /* opacity: 0.1; */
}
.readmore {
  /* border: 1px solid #fff;
  border-radius: 4px; */
  padding: 5px 15px;
}
.chakra-stack.mobilemenu {
  display: none !important;
}
@media only screen and (max-width: 768px) {
  .chakra-stack.mobilemenu {
    display: block !important;
    width: 100%;
    position: fixed;
    bottom: 0px;
    color: black;
    z-index: 99;
    height: 64px;
  }
}
.navigator-box.arrow-left:after {
  content: " ";
  position: absolute;
  left: 109px;
  top: 80px;
  border-top: 6px solid transparent;
  border-right: 26px solid black;
  border-left: none;
  border-bottom: 15px solid transparent;
}
.navigator-box-1.arrow-left:after {
  content: " ";
  position: absolute;
  left: 230px;
  top: 65px;
  border-top: 6px solid transparent;
  border-right: 26px solid black;
  border-left: none;
  border-bottom: 15px solid transparent;
}
/* p.chakra-text.type-animate { */
/* color: #000000; */
/* overflow: hidden; Ensures the content is not revealed until the animation
  /* border-right: 0.15em solid orange; /* The typwriter cursor */
/* white-space: wrap; Keeps the content on a single line
  margin: 0 auto; Gives that scrolling effect as the typing happens */
/* letter-spacing: 0.15em; Adjust as needed */
/* animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite; */
/* } */

/* The typing effect */
/* @keyframes typing {
  from {
    width: 50%;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
} */
.closeicon {
  color: #fff;
  background: black;
  border-radius: 24px !important;
  font-size: 11px !important;
  width: 22px !important;
  height: 22px !important;
}
div#chakra-modal-rightquizdrawer {
  position: absolute;
  right: 0px;
  top: 64px !important;
  bottom: 0px;
  width: 100%;
  transform: translateX(0%) translateY(0px) translateZ(0px);
}
@media only screen and (min-width: 1440px) {
  div#chakra-modal-rightquizdrawer {
    position: fixed;
    right: auto !important;
    top: 64px !important;
    bottom: 0px;
    max-width: 425px !important;
    /* transform: translateX(169%) translateY(0px) translateZ(0px) !important; */
    transform: translateX(65%) translateY(0px) translateZ(0px) !important;
  }
}
div#quizslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#quizslider .swiper-button-next {
  right: 140px;
  top: 60%;
  background-color: #00000000;
  height: 36px !important;
  width: 36px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/arrow-right1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#quizslider .swiper-button-prev {
  left: 140px;
  top: 60%;
  background-color: #00000000;
  height: 36px !important;
  width: 36px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/arrow-left1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#quizslider .swiper-button-next:after {
  content: none !important;
}
div#quizslider .swiper-button-prev:after {
  content: none !important;
}
div#verticalnavigation .swiper-button-next {
  right: 45%;
  top: 492px;
  background-color: #00000000;
  height: 20px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/down.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  /* transform: rotate(180deg); */
}
div#verticalnavigation .swiper-button-prev {
  left: 45%;
  top: 0px;
  background-color: #00000000;
  height: 20px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/arrow-up.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  /* transform: rotate(0deg); */
}
div#verticalnavigation .swiper-button-prev:after {
  content: none !important;
}
.react-datepicker-popper {
  padding: 8px;
  transform: translate(-210px, -56.25px) !important;
}
@media only screen and (max-width: 568px) {
  .react-datepicker-popper {
    padding: 8px;
    transform: translate(0px, -56.25px) !important;
  }
}
.react-datepicker {
  border-radius: 0px !important;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__year-wrapper {
  justify-content: center;
}
.react-datepicker__navigation-icon--previous {
  top: 5px !important;
}
.react-datepicker__navigation-icon--next {
  top: 5px !important;
}

div#artHeriatgeEdition .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#artHeriatgeEdition .swiper-button-next {
  right: -8px;
  top: 525px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#artHeriatgeEdition .swiper-button-prev {
  left: -10px;
  top: 525px;
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#artHeriatgeEdition .swiper-button-prev:after {
  content: none;
}
div#artHeriatgeEdition .swiper {
  width: 60% !important;
}
/* Need to confirm by content team */

/* div#integratedtimelinearrow .swiper {
  width: 50% !important;
} */
/* div#integratedtimelinearrow .swiper-button-next {
  display: none;
}
div#integratedtimelinearrow .swiper-button-prev{
  display: none;
} */
div#integratedtimelinearrow .swiper-button-next {
  right: 0% !important;
  border-radius: 0px !important;
  border: none !important;
  position: absolute;
  top: 15% !important;
  width: 15% !important;
  height: 60% !important;
  background: transparent !important;
}
div#integratedtimelinearrow .swiper-button-prev {
  left: 0% !important;
  border-radius: 0px !important;
  border: none !important;
  position: absolute;
  top: 15% !important;
  width: 15% !important;
  height: 60% !important;
  background: transparent !important;
}
@media only screen and (max-width: 768px) {
  div#integratedtimelinearrow .swiper-button-prev {
    height: 0% !important;
  }
  div#integratedtimelinearrow .swiper-button-next {
    height: 0% !important;
  }
}
div#integratedtimelinearrow .swiper-button-next:hover {
  cursor: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/r.png), default !important;
}
div#integratedtimelinearrow .swiper-button-prev:hover {
  cursor: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/l.png), default !important;
}
div#integratedtimelinearrow .swiper-button-prev:after {
  content: none;
}
div#integratedtimelinearrow .swiper-button-prev:after {
  content: none;
}
/* Stop Autoscrolling on integrated timeline */

/* @keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-100vw * 8))}
}
.autoslider .slide-track {
  animation: scroll linear infinite;
  animation-duration: 420s;
  display: flex;
  width: calc(80px * 100);
} */
@media only screen and (max-width: 568px) {
  .ds-sm-img {
    display: flex;
    height: 275px;
    width: auto;
    justify-content: center;
    border-radius: 0px;
  }
  .ds-sm-img img.chakra-image {
    width: auto;
  }
}
@media only screen and (max-width: 768px) {
  .explorepage-tablist {
    display: flex;
    flex-direction: column;
    gap: 24px !important;
    margin: 0 24px !important;
  }
}
@media only screen and (max-width: 415px) {
  .chakra-stack.vision-tabs {
    overflow-x: scroll;
  }
}
div#visionslideshowinside .swiper-button-prev {
  left: auto;
  right: 155px;
  background-color: #00000052;
  height: 34px !important;
  width: 34px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92% !important;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left-white.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}
@media only screen and (max-width: 568px) {
  div#visionslideshowinside .swiper-button-prev {
    left: auto;
    right: 100px;
    background-color: #00000052;
    height: 30px !important;
    width: 30px !important;
    color: #fff !important;
    border-radius: 50px;
    border: 1px solid #fff;
    top: 88% !important;
    z-index: 99999;
  }
}
div#visionslideshowinside .swiper-button-next {
  left: auto;
  right: 40px;
  background-color: #00000052;
  height: 34px !important;
  width: 34px !important;
  color: #fff !important;
  border-radius: 50px;
  border: 1px solid #fff;
  top: 92%;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right-white.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}
@media only screen and (max-width: 568px) {
  div#visionslideshowinside .swiper-button-next {
    left: auto;
    right: 12px !important;
    background-color: #00000052;
    height: 30px !important;
    width: 30px !important;
    color: #fff !important;
    border-radius: 50px;
    border: 1px solid #fff;
    top: 88% !important;
    z-index: 99999;
  }
}
.swiper.visionslideshowinside {
  background-color: #e8e8e8;
}
div#visionslideshowinside .swiper-wrapper {
  margin-bottom: 0px !important;
}
div#visionslideshowinside .swiper-pagination-fraction {
  top: 91%;
  left: -92px;
  width: 100%;
  text-align: end;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 568px) {
  div#visionslideshowinside .swiper-pagination-fraction {
    top: 84%;
    left: -55px;
    width: 100%;
    text-align: end;
    font-size: 16px;
    font-weight: 700;
  }
}
div#visionslideshowinside.swiper-button-next:after {
  display: none;
  content: none;
}
.textWrap {
  text-wrap: nowrap !important;
}
.textWrap:hover{
  color: black;
}
.keyword-btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #000;
  background-color: #eeeeee !important;
}
.primary-color {
  border: 1px solid #ff0000;
}
.secondary-color {
  border: 1px solid #0eac01;
}
.keyword-color {
  border: 1px solid #0029ff;
}
@keyframes moveBlob {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(150%);
  }
}

/* Style the button */
.blob-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  background-color: #ffc015;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

/* Style the blob animation */
.blob-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 50px;
  opacity: 0.5;
  background-color: #ffc015; /* Adjust color as needed */
  z-index: -1;
  animation: moveBlob 5s infinite alternate; /* Adjust animation duration as needed */
  transform: translateX(-100%);
}
.swiper-grid-column > .swiper-wrapper {
  flex-direction: unset !important;
}
.bookmark span.chakra-checkbox__control {
  width: 9px !important;
  height: 11px !important;
  border-right: 1px solid #333;
  border-bottom: 3px solid #333;
}
div#slideshowinsight .swiper-button-next {
  left: auto;
  right: 125px;
  height: 30px !important;
  width: 50px !important;
  top: 80%;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}
div#slideshowinsight .swiper-button-prev {
  left: auto;
  right: 216px;
  height: 30px !important;
  width: 50px !important;
  top: 80%;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-size: 25% auto !important;
  background-position: center !important;
  z-index: 99999 !important;
}

@media only screen and (max-width: 568px) {
  div#slideshowinsight .swiper-button-next {
    left: 55%;
    right: 12px !important;
    height: 30px !important;
    width: 30px !important;
    color: #fff !important;
    border-radius: 50px;
    border: 1px solid #fff;
    top: 107% !important;
    z-index: 99999;
  }
}
div#slideshowinsight .swiper-wrapper {
  margin-bottom: 0px !important;
}
div#slideshowinsight .swiper-pagination-fraction {
  top: 78%;
  left: -170px;
  width: 100%;
  text-align: end;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 568px) {
  div#slideshowinsight .swiper-pagination-fraction {
    top: 105%;
    left: auto;
    right: 50%;
    width: 100%;
    text-align: end;
    font-size: 16px;
    font-weight: 700;
  }
}
div#slideshowinsight .swiper-button-next:after {
  display: none;
  content: none;
}
div#slideshowinsight .swiper-button-prev:after {
  display: none;
  content: none;
}
.img-shadow img {
  background-color: white;
  box-shadow: 10px 10px 5px #e5e5e5;
}
div#slideshowinsight .swiper-slide-active {
  background-color: white;
  box-shadow: 10px 10px 5px #e5e5e5;
}
.quiz-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 130px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
.pegination-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: auto;
  cursor: pointer;
}
@media only screen and (max-width: 568px) {
  iframe#widget4 {
    height: 275px;
  }
  .no-wrap {
    text-wrap: nowrap !important;
  }
}
/* This css is used on SKK cast and credit tab  */
div#listgridviewbtn1 {
  z-index: 999;
  margin-top: 102px !important;
  margin-left: 860px;
  position: fixed;
  background-color: white;
}
div#listgridviewobjssb {
  z-index: 999;
  margin-top: 0px !important;
  margin-left: 860px;
  position: fixed;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  div#listgridviewbtn1 {
    z-index: 999;
    margin-top: 110px !important;
    margin-left: 0px;
    position: fixed;
    background-color: white;
  }
}
/* This css for all timeline text margin for mobile view */
@media only screen and (max-width: 768px) {
  .vertical-timeline-element {
    position: relative;
    margin: 0.1em 0 !important;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    display: none !important;
  }
  #timeline2
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    display: flex !important;
  }
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    animation: none !important;
  }
}
.card-arrow-left {
  position: "absolute";
  top: "25%";
  left: "-15px";
  cursor: pointer;
}
.card-arrow-rigth {
  position: "absolute";
  top: "25%";
  right: "-15px";
  cursor: pointer;
}
.chronology::-webkit-scrollbar {
  display: none;
}
/* This css using for Research category masterlist pages hover img text */

.content {
  position: relative;
  width: 100%;
  max-width: auto;
  margin: auto;
  overflow: hidden;
}
.content .content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: auto;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}
.fadeIn-bottom{
  top: 80%;
}
/* This css only for scroll section */
#scrollToIntroduction {
  scroll-margin-block-start: 160px;
    scroll-margin-block-end: 110px;
}
#scrollToChronology {
  scroll-margin-block-start: 160px;
    scroll-margin-block-end: 110px;
}
#scrollToMasterlists {
  scroll-margin-block-start: 160px;
    scroll-margin-block-end: 110px;
}
#scrollToPosts {
  scroll-margin-block-start: 160px;
    scroll-margin-block-end: 110px;
}
#scrollToInsights {
  scroll-margin-block-start: 160px;
    scroll-margin-block-end: 110px;
}
#scrollToResearchCentre {
  scroll-margin-block-start: 200px;
    scroll-margin-block-end: 70px;
}
div#rc_slider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#rc_slider .swiper-button-next {
  right: 60px;
  background-color: #00000000;
  height: 35px !important;
  width: 21px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/next.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#rc_slider .swiper-button-next::after {
  content: none !important;
}
div#rc_slider .swiper-button-prev::after {
  content: none !important;
}
div#rc_slider .swiper-button-prev {
  left: 60px;
  background-color: #00000000;
  height: 34px !important;
  width: 21px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/previous.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

div#allslideressay .swiper {
  margin-left: -55px;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#allslideressay .swiper-button-next:after {
  content: none !important;
}
div#allslideressay .swiper-button-prev:after {
  content: none !important;
}
div#allslideressay .swiper-button-next {
  right: 80px;
  top: 50%;
  background-color: #00000000;
  height: 18px !important;
  width: 11px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
div#allslideressay .swiper-button-prev {
  left: -28px;
  top: 50%;
  background-color: #00000000;
  height: 18px !important;
  width: 11px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.eventExhibitclosebtn {
  position: absolute !important;
  right: 0%;
  top: 1% !important;
  color: white !important;
  background-color: black !important;
  border-radius: 20px !important;
  font-size: 10px !important;
}

div#fullscreenimgslider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: unset !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 0;
}
div#fullscreenimgslider .swiper-button-next {
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top:92%;
  left:46%

}
div#fullscreenimgslider .swiper-button-prev {
  background-color: #00000000;
  height: 24px !important;
  width: 15px !important;
  color: #000 !important;
  border-radius: 50px;
  border: 0px solid #fff;
  font-weight: 900;
  background-image: url(https://d3f7r9v35rq5qh.cloudfront.net/ui/Chevron/chevron-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top:92%;
  left:42%;
}
.stage-score-bg.vertical-timeline-element {
  background-color: #FAD51C !important;
  border-radius: 40px;
}
.stage-score-bg.vertical-timeline-element-content {
  background-color: #FAD51C !important;
}
div#timeline4 .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 14px !important;
  width: auto;
  height: auto;
  border-radius: 0%;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline4 .vertical-timeline-element-content {
  position: relative;
  margin-left: 45px;
  background: #fff;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline4 .stage-score-bg.vertical-timeline-element-content p {
  position: relative;
  margin-left: 45px;
  background: #FAD51C;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: 0 0px 0 #ddd;
  box-shadow: 0 0px 0 #ddd;
}
div#timeline4 svg {
  display: initial;
  margin-right: 10px;
}
@media only screen and (min-width: 1170px) {
  div#timeline4.exhibition-timeline {
    margin: 0.5em 0 !important;
  }
}
@media only screen and (min-width: 768px) {
  div#timeline4 .vertical-timeline-element-content p {
    font-size: 16px !important;
    font-weight: 400;
  }
}
div#timeline4 .vertical-timeline-element-content p {
  margin: 0 !important;
  /* line-height: 2 !important; */
}
div#timeline4 .vertical-timeline-element-content .vertical-timeline-element-date {
display: none!important;
}

div#timeline4.score-iocn.vertical-timeline-element {
  padding-bottom: 6px!important;
  padding-top: 6px!important; 
  padding-right:20px!important;
}
div#timeline4 .vertical-timeline-element-content-arrow {
  border-right: 7px solid #fff0!important;
}
@media only screen and (min-width: 1550px) {
.acc-pannel-ht {
height: 600px;
}}
@media only screen and (max-width: 1520px) {
  .acc-pannel-ht {
  height: 400px;
  }}

  div#subleftsliderrajkapoor.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -65px;
    left: 98px !important;
  }
  @media only screen and (max-width: 568px) {
    div#subleftsliderrajkapoor.swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: -120px;
      left: 0px !important;
    }}
div#timeline4 .vertical-timeline-element-icon svg {
      display: block;
      width: 10px!important;
      height: 10px!important;
      position: relative;
      left: 50%;
      top: 50%;
      margin-left: -6.5px!important;
      margin-top: 12px!important;
  }
  .acc-pannel-ht::-webkit-scrollbar {
    width: 1px!important;
  }
  .box-sticky {
    position: sticky;
    top: 180px;
    flex-direction: row-reverse;
    width: auto;
    height: auto;
    z-index: 99;
    background-color: #ffffff;
    max-height: 580px;
  }